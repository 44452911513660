import {Button as MuiButton} from '@mui/material';
import {OverridableStringUnion} from '@mui/types';
import {Svg} from 'modules/theme/components/svg/Svg';
import {FC, FunctionComponent, SVGProps, ReactNode} from 'react';
import {
    ButtonPropsSizeOverrides,
    ButtonPropsVariantOverrides,
} from '@mui/material/Button/Button';

interface IButton {
    text: string | ReactNode;
    href?: string;
    small?: boolean;
    textBtn?: boolean;
    rounded?: boolean;
    tertiary?: boolean;
    disabled?: boolean;
    secondary?: boolean;
    quaternary?: boolean;
    fullWidth?: boolean;
    onClick?: () => void;
    type?: 'submit' | 'reset' | 'button' | undefined;
    endIcon?: FunctionComponent<SVGProps<SVGSVGElement>>;
    startIcon?: FunctionComponent<SVGProps<SVGSVGElement>>;
    testId?: string;
    ariaLabel?: string;
}

export const Button: FC<IButton> = ({
    text,
    href,
    small,
    textBtn,
    onClick,
    endIcon,
    rounded,
    tertiary,
    disabled,
    startIcon,
    secondary,
    fullWidth,
    quaternary,
    type = 'button',
    testId,
    ariaLabel
}) => {
    if (secondary && tertiary) {
        throw Error('You can use secondary and tertiary at same time. ');
    }

    if (startIcon && endIcon) {
        throw Error('You can use startIcon and endIcon at same time. ');
    }

    let variant: OverridableStringUnion<'text' | 'outlined' | 'contained',
        ButtonPropsVariantOverrides>;
    if (secondary) variant = 'secondary';
    if (tertiary) variant = 'tertiary';
    if (quaternary) variant = 'quaternary';
    if (rounded) variant = 'rounded';
    if (textBtn) variant = 'text';

    let size: OverridableStringUnion<'small' | 'medium' | 'large',
        ButtonPropsSizeOverrides>;
    if (small) size = 'small';

    return (
        <MuiButton
            type={type}
            size={size}
            href={href}
            onClick={onClick}
            variant={variant}
            disabled={disabled}
            fullWidth={fullWidth}
            aria-label={ariaLabel}
            data-test-id={testId ? testId : 'button'}
            sx={{textTransform: 'none'}}
            component={href ? 'a' : 'button'}
            endIcon={endIcon && <Svg icon component={endIcon} width={20} height={20}/>}
            startIcon={
                startIcon && <Svg icon component={startIcon} width={20} height={20}/>
            }
        >
            {text}
        </MuiButton>
    );
};
